import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as EditControlListSettingsModalName} from "../../../components/modals-new/edit-control-list-settings/EditControlListSettingsModal";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

export class CogControlListComponent extends React.Component {

    onOptionsClick = e => {
        this.props.show(EditControlListSettingsModalName, {list: this.props.value});
        e.stopPropagation();
    };

    render() {
        return (
            <Tooltip tooltipContent={"Ustawienia"} type={"info"}>
                <div onClick={this.onOptionsClick}>
                    <i className="fas fa-cog pointer"/>
                </div>
            </Tooltip>
        );
    }

}

CogControlListComponent = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(CogControlListComponent);

export default CogControlListComponent;