import React from "react";
import {cloneDeep} from "lodash";
import PropTypes from "prop-types";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {connect} from "react-redux";
import DocumentsViewer from "../../components/documents-viewer/DocumentsViewer";
import {getDocumentName, getLinkToDocument} from "../../utils/DocumentUtils";
import {withTranslation} from "react-i18next";
import Card from "../../components/basics/card/Card";
import "./_change-log-view.scss";
import {getDeviceNameByID} from "../../utils/DevicesUtils";
import moment from "moment";

const _DateFormat = "DD.MM.YYYY";

@connect((state) => {
    return {
        documents: state.documents.documents,
    }
})

class ChangeLogView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: 10
        }
    }

    onArrowClick = () => {
        this.props.history.goBack();
    };

    checkGatewaysCompilationData = (props) => {
        const {status} = props;
        const compilations = [...status.values()];
        if (compilations.length > 0) {
            return compilations;
        }
        return [];
    };


    render() {
        const {documents, t} = this.props;
        let logs = cloneDeep(documents);
        logs = logs.filter(o => o.Type === "CHANGELOG");
        logs.sort((o1, o2) => o2.DtaModTime - o1.DtaModTime);
        const data = this.checkGatewaysCompilationData(this.props);
        return (
            <div className={"change-log"}>
                <Header text={<>
                    <span><i className={'fas fa-fw fa-arrow-left pointer'} onClick={this.onArrowClick}/></span>
                    {t("changeLogView.header")}
                </>
                }
                />
                <ViewContainer>
                    {
                        logs.map((doc, index) => (
                            <DocumentsViewer key={index} header={getDocumentName(doc)} path={getLinkToDocument(doc)}
                                             time={doc.DtaCrtTime}/>
                        ))
                    }
                </ViewContainer>
                {
                    data.length > 1 &&
                    <ViewContainer>
                        <Card className={'gateway-viewer'} style={{minHeight: '10rem'}}>
                            <h4 className="align-content-center justify-content-between">Gateways</h4>
                            {
                                data.map((gw) => (
                                    <div>
                                        <p>{`${getDeviceNameByID(gw.GatewayID)}: ${gw.version}`} - {moment(gw.compilation).format(_DateFormat)}</p>
                                        <hr/>
                                    </div>
                                ))
                            }
                        </Card>
                    </ViewContainer>
                }
            </div>
        )
    }

}


ChangeLogView.propTypes = {
    path: PropTypes.string.isRequired
};

ChangeLogView = connect((state) => ({
    status: state.mqtt.status,
}))(ChangeLogView);

export default withTranslation()(ChangeLogView)



