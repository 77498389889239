import React from "react";
import "./_star-control-list.scss"
import {updateDictionaryDynamoDB} from "../../../actions/dictionaryActions";
import {connect} from "react-redux";
import Tooltip from "../../../components/basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";

export class StarControlListComponent extends React.Component {

    markAsFavorite = e => {
        const {value, user} = this.props;
        value.markAsFavorite(user.LocalUserID);
        this.props.dispatch(updateDictionaryDynamoDB(value.prepareBeanToSave(), user.ClientID, user.LocalUserID));
        e.stopPropagation();
    };

    render() {
        const {value: {WData: {Favorite}}, user, t} = this.props;
        let favorite = Array.isArray(Favorite) && Favorite.includes(user.LocalUserID);
        return (
            <Tooltip
                tooltipContent={favorite ? t("controlLists.mainView.removeFromFavorites") : t("controlLists.mainView.addToFavorites")}
                type={"info"}>
                <div className={favorite ? "star-control-list selected" : "star-control-list"}
                     onClick={this.markAsFavorite}>
                    <i className="fas fa-star"/>
                </div>
            </Tooltip>
        );
    }

}

StarControlListComponent = connect(state => ({
    user: state.user.user,
}))(StarControlListComponent);

export default withTranslation()(StarControlListComponent);