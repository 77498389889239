import React from "react";
import {
    calculateSowsToInseminationControlLists,
    getSowsToInseminationHeaders
} from "../../../../utils/ControlListsUtils";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import {connect} from "react-redux";
import MobileSowsToInseminationControlListRow from "./MobileSowsToInseminationControlListRow";

export class SowsToInseminationControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm} = props;
        this.state = {
            data: calculateSowsToInseminationControlLists(list, farm)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) {
            this.setState({
                data: calculateSowsToInseminationControlLists(nextProps.list, nextProps.farm)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    render() {
        const {name, list, description} = this.props;
        const {data} = this.state;
        let headers = getSowsToInseminationHeaders();
        return (
            <ControlListContainer name={name} list={list} data={data} description={description} headers={headers}
                                  mobileRow={<MobileSowsToInseminationControlListRow/>}/>
        );
    }

}

SowsToInseminationControlList = connect(state => ({
    farm: state.location.farm,
}))(SowsToInseminationControlList);

export default SowsToInseminationControlList;