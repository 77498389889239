import React from "react";
import {
    calculatePlannedParturitionsControlLists,
    getPlannedParturitionHeaders
} from "../../../../utils/ControlListsUtils";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import {connect} from "react-redux";
import MobilePlannedParturitionsControlListRow from "./MobilePlannedParturitionsControlListRow";
import moment from "moment";

export class PlannedParturitionsControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm} = props;
        this.state = {
            data: calculatePlannedParturitionsControlLists(list, farm)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) {
            this.setState({
                data: calculatePlannedParturitionsControlLists(nextProps.list, nextProps.farm)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    rowClassName = object => {
        if (object.parturitionDate.isBefore(moment().startOf("day"))) return "error";
    };

    render() {
        const {name, list, description} = this.props;
        const {data} = this.state;
        let headers = getPlannedParturitionHeaders();
        return (
            <ControlListContainer name={name} list={list} data={data} description={description} headers={headers}
                                  mobileRow={<MobilePlannedParturitionsControlListRow/>}
                                  rowClassName={this.rowClassName}/>
        );
    }

}

PlannedParturitionsControlList = connect(state => ({
    farm: state.location.farm,
}))(PlannedParturitionsControlList);

export default PlannedParturitionsControlList;