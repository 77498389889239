import React from "react";
import moment from "moment";
import "../_mobile-control-list-data-row.scss"
import {animalLocationValueFormatter, formatDose, formatMedicine} from "../../../../utils/ControlListsUtils";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";

export class MobileTreatmentControlListRow extends React.Component {

    isError(date) {
        return date.isBefore(moment().startOf("day"));
    }

    render() {
        const {t, object: {animal: {AnmNo1, PlcmntID}, date, weight, lastWeighting, medicine}} = this.props;
        let error = this.isError(date);
        return (
            <div className={`mobile-control-list-data-row${error ? " error" : ""}`}>
                <div className="mobile-control-list-data-row-container">
                    <div>
                        {t("animalNumber")}: <span className="font-weight-bold">{AnmNo1}</span>
                    </div>
                    <div>
                        {t("location")}: {animalLocationValueFormatter(PlcmntID)}
                    </div>
                    <div>
                        {t("weight")}: {weight ? convertWeightUnitTo(weight, {
                        unit: UnitTypes.MEDIUM,
                        showUnit: true
                    }) : "-"}
                    </div>
                    <div>
                        {t("lastWeighting")}: {lastWeighting ? moment(lastWeighting.EvTime).format("DD.MM.YYYY") : "-"}
                    </div>
                    <div>
                        {t("medicine")}: {formatMedicine(medicine)}
                    </div>
                    <div>
                        {t("dosage")}: {formatDose(medicine)}
                    </div>
                    <div>
                        {t("date")}: <span
                        className="font-weight-bold">{date.format("DD.MM.YYYY")}</span>
                    </div>
                </div>
                {
                    error &&
                    <div className="mobile-control-list-data-row-error">
                        <i className="fas fa-exclamation-circle"/>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()(MobileTreatmentControlListRow);