import React, {Component} from 'react';
import {connect} from 'react-redux';
import i18next from "i18next";


class ControlListFullWidthRow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            animalKinds: i18next.t("animalKind", {returnObjects: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                animalKinds: i18next.t("animalKind", {returnObjects: true})
            })
        }
    }

    render() {
        console.log(this.props);
        const {object: {animalKind}} = this.props;
        const {animalKinds} = this.state;
        return (
            <h6 className="control-list-full-width">{animalKinds[animalKind]}</h6>
        );
    }
}

export default connect(
)(ControlListFullWidthRow);