import React from "react";
import PropTypes from "prop-types"
import ControlList from "../../beans/ControlList";
import Card from "../../components/basics/card/Card";
import TableGrid from "../../components/basics/table-grid/TableGrid";
import {connect} from "react-redux";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import Printable from "../../components/basics/printable/Printable";
import {ModalName as EditControlListSettingsModalName} from "../../components/modals-new/edit-control-list-settings/EditControlListSettingsModal";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {checkHeader, emptyHeader} from "../../utils/ControlListsUtils";
import {withTranslation} from "react-i18next";
import {isEqual} from "lodash";

export class ControlListContainer extends React.Component {

    constructor(props) {
        super(props);

        const {list} = this.props;
        this.state = {
            printing: false,
            dates: list.getDatesForList(),
            edit: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            dates: nextProps.list.getDatesForList()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!isEqual(this.props, nextProps)) return true;
        return !isEqual(this.state, nextState);
    }

    componentWillUnmount() {
        this.setState({
            printing: false
        })
    }

    onPrintClick = () => {
        this.setState({
            printing: true,
            edit: false
        });
    };

    onOptionsClick = () => {
        console.log(this.props.list);
        this.props.show(EditControlListSettingsModalName, {list: this.props.list});
    };

    onPrintEnd = () => {
        this.setState({
            printing: false
        })
    };

    onEditClick = () => {
        this.setState(state => ({
            edit: !state.edit
        }))
    };

    render() {
        const {
            showPrintButton, name, description, t, headers, data, mobileRow, rowClassName, showEditButton,
            onEditClick, onRemoveClick, showEditAndRemoveOnRow
        } = this.props;
        console.log(this.props.list);
        const {printing, dates, edit} = this.state;
        let tmpHeaders = headers.slice(0);
        if (printing) {
            tmpHeaders.push(emptyHeader);
            tmpHeaders.push(checkHeader);
        }
        if (edit) {
            tmpHeaders.push(...[
                {
                    name: "",
                    component: props => showEditAndRemoveOnRow(props) ?
                        <i className="fas fa-edit pointer" onClick={() => onEditClick(props)}/> : null,
                    headerClassName: "index",
                    itemClassName: "index"
                },
                {
                    name: "",
                    component: props => showEditAndRemoveOnRow(props) ?
                        <i className="fas fa-trash pointer" onClick={() => onRemoveClick(props)}/> : null,
                    headerClassName: "index",
                    itemClassName: "index"
                }
            ])
        }
        return (
            <Card>
                <Printable printing={printing} name={name} additionalText={
                    <div>
                        {description}
                        <div>{t("controlLists.mainView.rangeOfDates")}: {dates.start.format("DD.MM.YYYY")} - {dates.end.format("DD.MM.YYYY")}</div>
                    </div>
                } onPrintEnd={this.onPrintEnd}>
                    <TableGrid data={data} headers={tmpHeaders} shouldIndex mobileRow={mobileRow}
                               rowClassName={rowClassName} showPagination={data.length > 100} paginationItems={100}/>
                </Printable>
                {
                    showPrintButton &&
                    <ButtonGroup fixed>
                        {
                            showEditButton &&
                            <Button buttonStyle={"round"} buttonColor={"secondary"}
                                    icon={<i className="fas fa-pencil-alt fa-fw"/>} onClick={this.onEditClick}/>
                        }
                        <Button buttonStyle={"round"} buttonColor={"info"} icon={<i className="fas fa-cog fa-fw"/>}
                                onClick={this.onOptionsClick}/>
                        <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-print fa-fw"/>}
                                onClick={this.onPrintClick}/>
                    </ButtonGroup>
                }
            </Card>
        );
    }

}

ControlListContainer.propTypes = {
    list: PropTypes.instanceOf(ControlList).isRequired,
    showPrintButton: PropTypes.bool,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    mobileRow: PropTypes.element.isRequired,
    rowClassName: PropTypes.func,
    showEditButton: PropTypes.bool,
    onEditClick: PropTypes.func,
    onRemoveClick: PropTypes.func,
    showEditAndRemoveOnRow: PropTypes.func
};

ControlListContainer.defaultProps = {
    showPrintButton: true,
    onEditClick: () => {
    },
    onRemoveClick: () => {
    },
    showEditAndRemoveOnRow: () => true
};

ControlListContainer = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(ControlListContainer);

export default withTranslation()(ControlListContainer);