import React, {Component} from "react";
import {connect} from "react-redux"
import {DevType} from "../../constans/devices";
import moment from "moment"
import NewIOT from "../../IOT/NewIOT"
import {getDebugIndex} from "../../IOT/device-functions/DispenserDriverFunctions";
import withRoles from "../../components/withRoles";
import {Roles} from "../../constans/roles";
import {getFeedingCurves} from "../../selectors/feedingSelector";

@connect((store) => {
    return {
        devices: store.farmDevices.devices,
        shadows: store.shadows.shadows,
        curves: getFeedingCurves(store)
    };
})
export class DebugDispenserView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDevice: "",
            gridData: []
        }
    }

    onChange(e) {
        if (e.target.value) {
            let device = JSON.parse(e.target.value);
            this.setState({selectedDevice: device, gridData: []});
            // subcribeAndGetMemoryData(device);
            // getDebugIndex(device);
            NewIOT.startSendingDeviceState(device);
            getDebugIndex(device);
        }
    };

   UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        if (this.state.selectedDevice) {
            let shadow = nextProps.shadows.get(this.state.selectedDevice.DevID);
            if (shadow && shadow.dispensers) {
                console.log(this.state.selectedDevice);
                let array = this.state.selectedDevice.Dispensers.map((dispenser, index) => {
                    let dispenserData = shadow.dispensers[index];
                    console.log(dispenserData);
                    let tmp = {
                        dispenserNumber: index + 1,
                        ...dispenserData
                    };
                    let curve = nextProps.curves.filter(item => item.SetData.Index === dispenserData.curveNumber - 1)[0];
                    console.log("curve", curve);
                    if (curve) {
                        let curveStart = moment(dispenserData.curveStart);
                        console.log(curveStart);
                        let diffInDays = moment().startOf("day").diff(curveStart, "days");
                        console.log("diffInDays", diffInDays);
                        if (diffInDays >= curve.SetData.Days.length) diffInDays = curve.SetData.Days.length - 1;
                        console.log("diffInDays", diffInDays);
                        let currentDay = curve.SetData.Days[diffInDays];
                        console.log("currentDay", currentDay);
                        tmp.curveIndex = diffInDays + 1;
                        tmp.scheduleIndex = currentDay.DailyPlan + 1;
                    }
                    if (shadow.debugDispensers) {
                        tmp.frame = shadow.debugDispensers[index];
                        tmp.hex = shadow.hexString;
                    }
                    return tmp;
                });
                this.setState({
                    gridData: array
                });
                console.log(array);
            }
        }
    }

    checkIfRowHasDifference(data) {
        if (data.frame) {
            if (data.curveNumber !== data.frame.curveNumber) return true;
            if (data.curveIndex !== undefined && data.curveIndex !== data.frame.curveIndex) return true;
            if (data.scheduleIndex !== undefined && data.scheduleIndex !== data.frame.scheduleIndex) return true;
            if (data.curveShift !== undefined && data.curveShift !== data.frame.curveShift) return true;
            if (data.doseCorrection !== undefined && data.doseCorrection !== data.frame.doseCorrection) return true;
            if (data.denseFoodMaxYield !== undefined && data.denseFoodMaxYield !== data.frame.denseEfficiency) return true;
            if (data.looseFoodMaxYield !== undefined && data.looseFoodMaxYield !== data.frame.looseEfficiency) return true
        }

        return false;
    }

    checkIfCellHasDifference(data, field, fieldDebugIndex) {
        if (data.frame) {
            console.log(data[field]);
            if (data[field] !== undefined && data[field] !== data.frame[fieldDebugIndex]) return true;
        }
        return false;
    }

    render() {
        let devices = this.props.devices.filter(item => item.DevType === DevType.DISPENSER).sort((a1, a2) => a1.Address - a2.Address);
        return (
            <div>
                <select onChange={(e) => this.onChange(e)} value={JSON.stringify(this.state.selectedDevice)}>
                    <option value={""}/>
                    {
                        devices.map(dev => <option key={dev.DevID}
                                                   value={JSON.stringify(dev)}>{`${dev.Name}/${dev.DevID}/${dev.Address}`}</option>)
                    }
                </select>
                {
                    this.state.gridData.length > 0 &&
                    <table style={{width: "100%", marginTop: 10}} className="grid">
                        <thead>
                        <tr>
                            <th>
                                Numer dozownika
                            </th>
                            <th>
                                Numer krzywej
                            </th>
                            <th>
                                Indeks krzywej
                            </th>
                            <th>
                                Indeks harmonogramu
                            </th>
                            <th>
                                Indeks dawki
                            </th>
                            <th>
                                Przusnięcie dnia
                            </th>
                            <th>
                                Przesunięcie dawki
                            </th>
                            <th>
                                Wydajność gęsta
                            </th>
                            <th>
                                Wydajność rzadka
                            </th>
                            <th>
                                Ostatni dzień krzywej
                            </th>
                            <th>
                                Opcje dozownika
                            </th>
                            <th>
                                Dawka zadana
                            </th>
                            <th>
                                Dawka pozostała
                            </th>
                            <th>
                                Dawka dzienna
                            </th>
                            <th>
                                Dawka procentowa
                            </th>
                            <th>
                                Ramka diagnostyczna
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.gridData.map((data, index) =>
                                <tr key={index} className={this.checkIfRowHasDifference(data) ? "debug-errors" : ""}>
                                    <td>{data.dispenserNumber}</td>
                                    <td style={this.checkIfCellHasDifference(data, "curveNumber", "curveNumber") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.curveNumber}/${data.frame ? data.frame.curveNumber : ""}`}</td>
                                    <td style={this.checkIfCellHasDifference(data, "curveIndex", "curveIndex") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.curveIndex}/${data.frame ? data.frame.curveIndex : ""}`}</td>
                                    <td style={this.checkIfCellHasDifference(data, "scheduleIndex", "scheduleNumber") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.scheduleIndex}/${data.frame ? data.frame.scheduleNumber : ""}`}</td>
                                    <td>{data.frame ? data.frame.scheduleIndex : ""}</td>
                                    <td style={this.checkIfCellHasDifference(data, "curveShift", "curveShift") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.curveShift}/${data.frame ? data.frame.curveShift : ""}`}</td>
                                    <td style={this.checkIfCellHasDifference(data, "doseCorrection", "doseCorrection") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.doseCorrection}/${data.frame ? data.frame.doseCorrection : ""}`}</td>
                                    <td style={this.checkIfCellHasDifference(data, "foodType1Yield", "denseEfficiency") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.foodType1Yield}/${data.frame ? data.frame.yield0 : ""}`}</td>
                                    <td style={this.checkIfCellHasDifference(data, "foodType2Yield", "looseEfficiency") ? {
                                        background: "#f44b4b",
                                        color: "#fff"
                                    } : {}}>{`${data.foodType2Yield}/${data.frame ? data.frame.yield1 : ""}`}</td>
                                    <td>{data.frame ? data.frame.lastCurveDay : ""}</td>
                                    <td>{data.frame ? data.frame.status : ""}</td>
                                    <td>{data.frame ? data.frame.doseGiven : ""}</td>
                                    <td>{data.frame ? data.frame.doseLeft : ""}</td>
                                    <td>{data.frame ? data.frame.doseDaily : ""}</td>
                                    <td>{data.frame ? data.frame.dosePercent : ""}</td>
                                    <td>{data.hex}</td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                }
            </div>
        );
    }
}
export const _DebugDispenserView = DebugDispenserView;

DebugDispenserView = withRoles({roles: [Roles._SERVICE], showComponent: true})(DebugDispenserView);
export default DebugDispenserView;