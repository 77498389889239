import React from "react";
import {animalLocationValueFormatter} from "../../../../utils/ControlListsUtils";
import "../_mobile-control-list-data-row.scss";
import {withTranslation} from "react-i18next";

export class MobileSowsToInseminationControlListRow extends React.Component {

    render() {
        const {t, object: {animal: {AnmNo1, PlcmntID}}} = this.props;
        return (
            <div className="mobile-control-list-data-row">
                <div className="mobile-control-list-data-row-container">
                    <div>
                        {t("animalNumber")}: <span
                        className="font-weight-bold">{AnmNo1}</span>
                    </div>
                    <div>
                        {t('location')}: {animalLocationValueFormatter(PlcmntID)}
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(MobileSowsToInseminationControlListRow);