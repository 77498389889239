import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Header from "../../components/basics/header/Header";
import Alarms from "../new-farm-view/tabs/Alarms";
import ViewContainer from "../../components/basics/view-container/ViewContainer";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class AlarmsView extends Component {
    render() {
        const {t} = this.props;
        return (
            <div>
                <Header text={t("alarms")}/>
                <ViewContainer>
                    <Alarms/>
                </ViewContainer>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(AlarmsView);