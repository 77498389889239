import React from "react";
import {calculateUSGControlLists, getUSGHeaders} from "../../../../utils/ControlListsUtils";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import {connect} from "react-redux";
import MobileUSGControlListRow from "./MobileUSGControlListRow";
import moment from "moment";

export class USGControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm} = props;
        this.state = {
            data: calculateUSGControlLists(list, farm)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) {
            this.setState({
                data: calculateUSGControlLists(nextProps.list, nextProps.farm)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    rowClassName = object => {
        if (object.date.isBefore(moment().startOf("day"))) return "error";
    };

    render() {
        const {name, list, description} = this.props;
        const {data} = this.state;
        let headers = getUSGHeaders();
        return (
            <ControlListContainer name={name} list={list} data={data} description={description} headers={headers}
                                  mobileRow={<MobileUSGControlListRow/>} rowClassName={this.rowClassName}/>
        );
    }

}

USGControlList = connect(state => ({
    farm: state.location.farm,
}))(USGControlList);

export default USGControlList;