import React from "react";
import PropTypes from "prop-types"
import ControlList from "../../../../beans/ControlList";
import {connect} from "react-redux";
import {calculateRemanentControlList, getRemanentHeaders} from "../../../../utils/ControlListsUtils";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import moment from "moment";
import MobileRemanentControlListRow from "./MobileRemanentControlListRow";

export class RemanentControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm} = props;

        this.state = {
            data: calculateRemanentControlList(list, farm)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) {
            this.setState({
                data: calculateRemanentControlList(nextProps.list, nextProps.farm)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    rowClassName = (object) => {
        if (object.parturition && object.parturition.EvTime < moment().startOf("day")) return "error";
    }

    render() {
        const {name, description, list} = this.props;
        const {data} = this.state;
        const headers = getRemanentHeaders();
        return (
            <ControlListContainer headers={headers} description={description} list={list} data={data} name={name}
                                  rowClassName={this.rowClassName}
                                  mobileRow={<MobileRemanentControlListRow rowClassName={this.rowClassName}/>}/>
        );
    }

}

RemanentControlList = connect(state => ({
    farm: state.location.farm,
}))(RemanentControlList);

RemanentControlList.propTypes = {
    list: PropTypes.instanceOf(ControlList).isRequired,
    showPrintButton: PropTypes.bool,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

RemanentControlList.defaultProps = {
    showPrintButton: true
}

export default RemanentControlList;