import React from "react";
import {animalLocationValueFormatter} from "../../../../utils/ControlListsUtils";
import moment from "moment";
import "../_mobile-control-list-data-row.scss";
import {withTranslation} from "react-i18next";

export class MobileRemanentControlListRow extends React.Component {

    isError(EvTime) {
        return EvTime < moment().startOf("day");
    }

    render() {
        const {t, object: {animal: {AnmNo1, PlcmntID}, parturition: {EvTime}, alive, nipples}} = this.props;
        let error = this.isError(EvTime);
        return (
            <div className={`mobile-control-list-data-row${error ? " error" : ""}`}>
                <div className="mobile-control-list-data-row-container">
                    <div>
                        {t("animalNumber")}: <span className="font-weight-bold">{AnmNo1}</span>
                    </div>
                    <div>
                        {t("location")}: {animalLocationValueFormatter(PlcmntID)}
                    </div>
                    <div>
                        {t("parturitionDate")}: {moment(EvTime).format("DD.MM.YYYY")}
                    </div>
                    <div>
                        {t("alivePiglets")}: {alive}
                    </div>
                    <div>
                        {t("activeNipples")}: {nipples || "-"}
                    </div>
                </div>
                {
                    error &&
                    <div className="mobile-control-list-data-row-error">
                        <i className="fas fa-exclamation-circle"/>
                    </div>
                }
            </div>
        );
    }

}

export default withTranslation()(MobileRemanentControlListRow);
