import React from "react";
import {calculateTreatmentControlLists, getTreatmentHeaders} from "../../../../utils/ControlListsUtils";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import {connect} from "react-redux";
import MobileTreatmentControlListRow from "./MobileTreatmentControlListRow";
import moment from "moment";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../../components/modals-new/confirm-modal/ConfirmModal";
import {updateEvent} from "../../../../actions/eventsActions";
import EditTreatmentModal, {ModalName as EditTreatmentModalName} from "../../../../components/modals-new/edit-treatment-modal/EditTreatmentModal";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class TreatmentControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm, animalKind, medicineType} = props;
        this.state = {
            data: calculateTreatmentControlLists(list, farm, animalKind, medicineType)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list) || !_.isEqual(this.props.events, nextProps.events)) {
            this.setState({
                data: calculateTreatmentControlLists(nextProps.list, nextProps.farm, nextProps.animalKind, nextProps.medicineType)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) return true;
        return !_.isEqual(this.state, nextState);
    }

    rowClassName = object => {
        if (object.date.isBefore(moment().startOf("day"))) return "error";
    };

    onRemoveTreatmentClick = data => {
        const {t} = this.props;
        const {value: {event, date, treatmentIndex}} = data;
        this.props.show(ConfirmModalName, {
            title: t("dateTreatmentRemoveTitle"),
            text: i18next.t("dateRemoveText", {date: date.format("DD.MM.YYYY")}),
            confirmText: t("yes"),
            onConfirmed: props => {
                const {user} = this.props;
                let treatment = event.clone();
                treatment.removeDate(date.toDate().getTime(), treatmentIndex);
                this.props.updateEvent(treatment.prepareBeanToSave(), user.ClientID, user.LocalUserID);
                props.handleHide();
            }
        })
    };

    onEditClick = data => {
        this.props.show(EditTreatmentModalName, {treatment: data.value.event});
    };

    showEditAndRemoveOnRow = props => !!props.value.event;

    render() {
        const {name, list, description} = this.props;
        const {data} = this.state;
        let headers = getTreatmentHeaders();
        return (
            <>
                <ControlListContainer name={name} list={list} data={data} description={description} headers={headers}
                                      mobileRow={<MobileTreatmentControlListRow/>} rowClassName={this.rowClassName}
                                      showEditButton onRemoveClick={this.onRemoveTreatmentClick}
                                      onEditClick={this.onEditClick}
                                      showEditAndRemoveOnRow={this.showEditAndRemoveOnRow}/>
                <EditTreatmentModal/>
            </>
        );
    }

}

TreatmentControlList = connect(
    state => ({
        farm: state.location.farm,
        user: state.user.user,
        events: state.events.events
    }),
    dispatch => bindActionCreators({show, updateEvent}, dispatch)
)(TreatmentControlList);

export default withTranslation()(TreatmentControlList);