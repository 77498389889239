import React from "react";
import {calculateConditionControlLists, getConditionHeaders} from "../../../../utils/ControlListsUtils";
import PropTypes from "prop-types";
import ControlList from "../../../../beans/ControlList";
import {connect} from "react-redux";
import _ from "lodash";
import ControlListContainer from "../../ControlListContainer";
import MobileConditionControlListRow from "./MobileConditionControlListRow";
import moment from "moment";

export class ConditionControlList extends React.Component {

    constructor(props) {
        super(props);

        const {list, farm} = props;
        this.state = {
            data: calculateConditionControlLists(list, farm)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.list, nextProps.list)) {
            this.setState({
                data: calculateConditionControlLists(nextProps.list, nextProps.farm)
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    rowClassName = object => {
        if (object.date.isBefore(moment().startOf("day"))) return "error";
    };

    render() {
        const {name, list, description} = this.props;
        const {data} = this.state;
        let headers = getConditionHeaders();
        return (
            <ControlListContainer name={name} list={list} data={data} description={description} headers={headers}
                                  mobileRow={<MobileConditionControlListRow/>} rowClassName={this.rowClassName}/>
        );
    }

}

ConditionControlList = connect(state => ({
    farm: state.location.farm,
}))(ConditionControlList);

ConditionControlList.propTypes = {
    list: PropTypes.instanceOf(ControlList).isRequired,
    showPrintButton: PropTypes.bool,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

ConditionControlList.defaultProps = {
    showPrintButton: true
};

export default ConditionControlList;