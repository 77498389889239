import React from "react";
import {Col, Row} from "react-bootstrap";
import "./_confirm-user-view.scss";
import logo from "../../resources/images/logos/fetura_cloud_colour.svg";
import {confirmUserAccount} from "../../actions/userActions";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

@connect()
export class ConfirmUserView extends React.Component {

    componentDidMount() {
        const {match: { params }, dispatch, history} = this.props;
        dispatch(confirmUserAccount(params.username, params.code));
        history.push('/login');
    }

    render() {
        const {t} = this.props;
        return (
            <div className={'confirm-wrapper'}>
                <Row bsPrefix="row h-100 justify-content-center align-items-center w-100 mx-auto">
                    <Col lg={4}>
                        <img src={logo} alt="fetura-logo"/>
                        <div className={"login-info"}>
                            <p>{t("confirmUserView.authorization")}</p>
                            <div id="wave">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withTranslation()(ConfirmUserView);