import React from "react";
import "./_mobile-control-list-row.scss"
import StarControlListComponent from "./grid-renderers/StarControlListComponent";
import CogControlListComponent from "./grid-renderers/CogControlListComponent";
import PrintControlListComponent from "./grid-renderers/PrintControlListComponent";

export class MobileControlListRow extends React.Component {

    onClickDiv = () => {
        this.props.onRowClick(this.props.object);
    }

    render() {
        const {object, getName, getDescription, onPrintClick} = this.props;
        return (
            <div className="mobile-control-list-row" onClick={this.onClickDiv}>
                <div className="mobile-control-list-row-info">
                    <div className="font-weight-bold">{getName(object)}</div>
                    <div>{getDescription(object)}</div>
                </div>
                <StarControlListComponent value={object}/>
                <CogControlListComponent value={object}/>
                <PrintControlListComponent value={object} onClick={onPrintClick}/>
            </div>
        );
    }

}

export default MobileControlListRow;