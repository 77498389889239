import React from "react";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import {connect} from "react-redux";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ScrollAndTrigger from "../../components/scroll-and-trigger/ScrollAndTrigger";
import Checkbox from "../../components/basics/checkbox/Checkbox";
import Card from "../../components/basics/card/Card";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import NotFound from "../../components/NotFound";
import PropTypes from "prop-types";
import _ from "lodash";
import {withRouter} from "react-router-dom";
import {markDocumentAsRead} from "../../actions/documentsActions";
import {withTranslation} from "react-i18next";


const initialValue = {
    read: undefined,
    loaded: false,
    fetched: false,
    time: 0,
    data: undefined
};

@connect(state => ({
    locale: state.language.locale,
    documents: state.documents.documents,
    user: state.user.user,
}))

class AcceptDocumentView extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialValue;
        const {history, match: {params: {documentId}}, documents=[]} = this.props;
        let documentToRead = documents.find(o=> o.DocID === documentId);
        if (!documentToRead) {
            history.push("/")
        }
        this.checkIfDocRead();
        this.state = {
            ...this.state,
            documentToRead
        }
    }
    checkIfDocRead = (props=this.props) => {
        const {user, history, match: {params: {documentId}}} = props;
        if((user.DocRead || []).includes(documentId)) {
            history.push("/");
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.checkIfDocRead(nextProps);
    }

    UNSAFE_componentWillMount() {
        const {documentToRead} = this.state;
        if(documentToRead){
            if (_.isString(documentToRead.Link)) {
                this.fetchDocument(documentToRead.Link);
            } else {
                const {locale} = this.props;
                let index = _.findIndex(documentToRead.Lang, (o => o === locale));
                this.fetchDocument(documentToRead.Link[index > -1 ? index : 0]);
            }
        }
    }

    fetchDocument = (path) => {
        this.setState({
            initialValue
        }, () => {
            fetch(path).then(res => {
                if (res.status !== 200) {
                    return Promise.reject(res);
                } else {
                    return res.text();
                }
            }).then(data => {
                this.setState({
                        ...initialValue,
                        fetched: true,
                        loaded: true,
                        data: data
                    }
                )
            }).catch(e => {
                this.setState({
                    ...initialValue,
                    fetched: true,
                    loaded: false
                })
            })
        })


    };

    onReadClick = () => {
        const {dispatch, history} = this.props;
        const {documentToRead: {DocID}} = this.state;
        dispatch(markDocumentAsRead(DocID));
        history.push("/");

    };


    render() {
        const {
            read,
            loaded,
            fetched,
            time,
            data,
            documentToRead
        } = this.state;
        const {locale, t} = this.props;
        let name = _.get(documentToRead,'Name');
        // console.log(name);
        let index = _.findIndex(_.get(documentToRead,'Lang',[]), (o => o === locale));
        name = _.isArray(name) ? name[index > -1 ? index : 0] : name;
        return (
            <div className={"accept-document-view"}>
                <Header text={name}/>
                <ViewContainer>
                    <Card style={{minHeight: '10rem'}}>
                        {
                            <LoadingComponent isLoading={!fetched}/>
                        }
                        {
                            fetched && loaded &&
                            <>
                                <ScrollAndTrigger style={{maxHeight: 'calc(90vh - 10rem'}} onTriggered={(time) => this.setState({
                                    time: time
                                })}>
                                    <div className={"document-content"} dangerouslySetInnerHTML={{__html: data}}/>
                                </ScrollAndTrigger>
                                <Checkbox label={t("readAndAccept")} disabled={!time} checked={read}
                                          onChange={() => this.setState({
                                              read: !read
                                          })}/>
                            </>
                        }
                        {
                            fetched && !loaded &&
                            <NotFound/>
                        }
                    </Card>
                    <ButtonGroup fixed={true}>
                        <Button onClick={()=>this.onReadClick()} buttonStyle={"round"} buttonColor={read ? "success" : "info"}
                                disabled={!read} icon={<i className="fas fa-check"/>}/>
                    </ButtonGroup>
                </ViewContainer>
            </div>
        )
    }

}


AcceptDocumentView.propTypes = {
    documentToRead: PropTypes.string.isRequired,
};
export const _AcceptDocumentView = AcceptDocumentView;
AcceptDocumentView = withRouter(AcceptDocumentView);
export default withTranslation()(AcceptDocumentView);



