import React from "react";
import Header from "../../components/basics/header/Header";
import ViewContainer from "../../components/basics/view-container/ViewContainer";
import TableGrid from "../../components/basics/table-grid/TableGrid";
import StarControlListComponent from "./grid-renderers/StarControlListComponent";
import Card from "../../components/basics/card/Card";
import CogControlListComponent from "./grid-renderers/CogControlListComponent";
import MobileControlListRow from "./MobileControlListRow";
import TabContainer from "../../components/basics/tabs/TabContainer";
import Tab from "../../components/basics/tabs/Tab";
import _ from "lodash"
import {connect} from "react-redux";
import {controlLists} from "../../constans/controlLists";
import EditControlListSettingsModal
    from "../../components/modals-new/edit-control-list-settings/EditControlListSettingsModal";
import InseminatiosControlList from "./control-lists/inseminations/InseminatiosControlList";
import {getAllDictionaries} from "../../actions/dictionaryActions";
import {CSSTransition} from "react-transition-group";
import PrintControlListComponent from "./grid-renderers/PrintControlListComponent";
import Printable from "../../components/basics/printable/Printable";
import PrintableTableGrid from "../../components/basics/table-grid/printable-table-grid/PrintableTableGrid";
import {
    calculateConditionControlLists,
    calculateInseminationsControlLists,
    calculatePlannedParturitionsControlLists,
    calculateSowsToInseminationControlLists,
    calculateSowsToTransferControlLists,
    calculateTreatmentControlLists,
    calculateUSGControlLists,
    checkHeader,
    getConditionHeaders, getControlListName,
    getInseminationHeaders,
    getPlannedParturitionHeaders,
    getSowsToInseminationHeaders,
    getSowsToTransferHeaders,
    getTreatmentHeaders,
    getUSGHeaders
} from "../../utils/ControlListsUtils";
import ConditionControlList from "./control-lists/condition/ConditionControlList";
import USGControlList from "./control-lists/usg/USGControlList";
import SowsToInseminationControlList from "./control-lists/sows-to-inseminations/SowsToInseminationControlList";
import SowsToTransferControlList from "./control-lists/sows-to-transfer/SowsToTransferControlList";
import PlannedParturitionsControlList from "./control-lists/planned-parturitions/PlannedParturitionsControlList";
import TreatmentControlList from "./control-lists/treatment-control-list/TreatmentControlList";
import {AnimalTypes} from "../../constans/animalTypes";
import {medicineTypes} from "../../constans/medicine";
import RemanentControlList from "./control-lists/remanent/RemanentControlList";
import "./_control-list-view.scss"
import ControlListFullWidthRow from "./ControlListFullWidthRow";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class ControlListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.createData(props),
            printing: false,
            headers: [],
            data: [],
            name: "",
            description: "",
            dates: null,

        };
        this.props.dispatch(getAllDictionaries())
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.match.params.active !== nextProps.match.params.active) {
            this.setState({
                active: this.getTabIndexFromPathParams(this.state.tabs, nextProps.match.params.active)
            });
        }
        if (!_.isEqual(this.props.controlLists, nextProps.controlLists)) {
            this.setState(this.createData(nextProps, this.props.controlLists.length === 0));
        }
    }

    componentWillUnmount() {
        this.setState({
            printing: false
        })
    }

    getName = (value) => {
        return getControlListName(value.WordID);
    };

    getDescription = value => {
        const {t} = this.props;
        switch (value.WordID) {
            case controlLists.INSEMINATIONS:
                let inseminationTime = value.WData.InseminationTime;
                return i18next.t("controlLists.mainView.inseminationDescription", {count: Array.isArray(inseminationTime) ? `${inseminationTime[0]} - ${inseminationTime[1]}` : inseminationTime});
            case controlLists.CONDITION:
                let conditionTimes = value.WData.ConditionDays;
                return i18next.t("controlLists.mainView.conditionsDescription", {amount: conditionTimes.join(", ")});
            case controlLists.USG:
                let usgTimes = value.WData.USGDays;
                return i18next.t("controlLists.mainView.usgDescription", {amount: usgTimes.join(", ")});
            case controlLists.SOWS_TO_INSEMINATION:
                return t("controlLists.mainView.sowsToInseminationDescription");
            case controlLists.SOWS_TO_TRANSFER:
                return t("controlLists.mainView.sowsToTransferDescription");
            case controlLists.PLANNED_PARTURITIONS:
                return t("controlLists.mainView.plannedParturitionsDescriptions");
            case controlLists.SOWS_TO_GRAFTING:
                return t("controlLists.mainView.sowsToGraftingDescription");
            case controlLists.BOARS_TO_GRAFRING:
                return t("controlLists.mainView.boarsToGraftingDescription");
            case controlLists.PORKER_TO_GRAFTING:
                return t("controlLists.mainView.porkerToGraftingDescription");
            case controlLists.PIGLET_TO_GRAFTING:
                return t("controlLists.mainView.pigletToGraftingDescription");
            case controlLists.SOWS_FORAGE:
                return t("controlLists.mainView.sowsForageDescription");
            case controlLists.BOARS_FORAGE:
                return t("controlLists.mainView.boarsForageDescription");
            case controlLists.PORKER_FORAGE:
                return t("controlLists.mainView.porkerForageDescription");
            case controlLists.PIGLET_FORAGE:
                return t("controlLists.mainView.pigletForageDescription");
            case controlLists.SOWS_DOSATRON:
                return t("controlLists.mainView.sowsDosatronDescription");
            case controlLists.BOARS_DOSATRON:
                return t("controlLists.mainView.boarsDosatronDescription");
            case controlLists.PORKER_DOSATRON:
                return t("controlLists.mainView.porkerDosatronDescription");
            case controlLists.PIGLET_DOSATRON:
                return t("controlLists.mainView.pigletDosatronDescription");
            case controlLists.SOWS_STIMULATOR:
                return t("controlLists.mainView.sowsStimulatorDescription");
            case controlLists.REMANENT:
                return t("controlLists.mainView.remanentDescription");
            default:
                return "";
        }
    };

    createData(props, findActive = true) {
        const {controlLists} = props;

        let tabs = this.getTabs(controlLists, props);
        let obj = {
            lists: _.cloneDeep(controlLists).sort((a, b) => {
                let animalKindA = i18next.t("animalKind", {returnObjects: true})[this.getAnimalKind(a)];
                let animalKindB = i18next.t("animalKind", {returnObjects: true})[this.getAnimalKind(b)];
                if (!animalKindA && !animalKindB) return 0;
                if (!animalKindA) return 1;
                if (!animalKindB) return -1;
                return animalKindA.localeCompare(animalKindB);
            }),
            tabs,
        };
        if (findActive) {
            obj.active = this.getTabIndexFromPathParams(tabs, props.match.params.active);
        }
        return obj;
    }

    getTabIndexFromPathParams(tabs, active) {
        let index = _.findIndex(tabs, o => o.key === active);
        return index >= 0 ? index : null;
    }

    getTabs(lists, props) {
        const {t, user: {LocalUserID}} = props;
        let tabs = [{name: t("all"), key: "all"}];
        for (let list of lists) {
            if (Array.isArray(list.WData.Favorite) && list.WData.Favorite.includes(LocalUserID)) tabs.push({
                name: this.getName(list),
                key: list.WordID
            });
        }
        return tabs;
    }

    onTabChange = key => {
        const {tabs} = this.state;
        const {match: {params: {FarmID}}} = this.props;
        this.props.history.push(`/${FarmID}/controlLists/${tabs[key].key}`);
    };

    onRowClick = object => {
        const {match: {params: {FarmID}}} = this.props;
        this.props.history.push(`/${FarmID}/controlLists/${object.WordID}`);
    };

    getComponentClass(list) {
        switch (list.WordID) {
            case controlLists.INSEMINATIONS:
                return InseminatiosControlList;
            case controlLists.CONDITION:
                return ConditionControlList;
            case controlLists.USG:
                return USGControlList;
            case controlLists.SOWS_TO_INSEMINATION:
                return SowsToInseminationControlList;
            case controlLists.SOWS_TO_TRANSFER:
                return SowsToTransferControlList;
            case controlLists.PLANNED_PARTURITIONS:
                return PlannedParturitionsControlList;
            case controlLists.SOWS_TO_GRAFTING:
            case controlLists.BOARS_TO_GRAFRING:
            case controlLists.PORKER_TO_GRAFTING:
            case controlLists.PIGLET_TO_GRAFTING:
            case controlLists.SOWS_FORAGE:
            case controlLists.BOARS_FORAGE:
            case controlLists.PORKER_FORAGE:
            case controlLists.PIGLET_FORAGE:
            case controlLists.SOWS_DOSATRON:
            case controlLists.BOARS_DOSATRON:
            case controlLists.PORKER_DOSATRON:
            case controlLists.PIGLET_DOSATRON:
            case controlLists.SOWS_STIMULATOR:
                return TreatmentControlList;
            case controlLists.REMANENT:
                return RemanentControlList;
            default:
                return "div";
        }
    }

    getAnimalKind(list) {
        switch (list.WordID) {
            case controlLists.SOWS_TO_GRAFTING:
            case controlLists.SOWS_FORAGE:
            case controlLists.SOWS_DOSATRON:
            case controlLists.SOWS_STIMULATOR:
            case controlLists.SOWS_TO_TRANSFER:
            case controlLists.REMANENT:
            case controlLists.INSEMINATIONS:
            case controlLists.USG:
            case controlLists.CONDITION:
            case controlLists.PLANNED_PARTURITIONS:
            case controlLists.SOWS_TO_INSEMINATION:
                return AnimalTypes.SOW;
            case controlLists.BOARS_TO_GRAFRING:
            case controlLists.BOARS_FORAGE:
            case controlLists.BOARS_DOSATRON:
                return AnimalTypes.BOAR;
            case controlLists.PORKER_TO_GRAFTING:
            case controlLists.PORKER_FORAGE:
            case controlLists.PORKER_DOSATRON:
                return AnimalTypes.PORKER;
            case controlLists.PIGLET_TO_GRAFTING:
            case controlLists.PIGLET_FORAGE:
            case controlLists.PIGLET_DOSATRON:
                return AnimalTypes.PIGLET;
            default:
                return null;
        }
    }

    getMedicineType(list) {
        switch (list.WordID) {
            case controlLists.SOWS_TO_GRAFTING:
            case controlLists.BOARS_TO_GRAFRING:
            case controlLists.PORKER_TO_GRAFTING:
            case controlLists.PIGLET_TO_GRAFTING:
                return medicineTypes.VACCINE;
            case controlLists.SOWS_FORAGE:
            case controlLists.BOARS_FORAGE:
            case controlLists.PORKER_FORAGE:
            case controlLists.PIGLET_FORAGE:
                return medicineTypes.FORAGE;
            case controlLists.SOWS_DOSATRON:
            case controlLists.BOARS_DOSATRON:
            case controlLists.PORKER_DOSATRON:
            case controlLists.PIGLET_DOSATRON:
                return medicineTypes.DOSATRON;
            case controlLists.SOWS_STIMULATOR:
                return medicineTypes.STIMULATOR;
            default:
                return null;
        }
    }

    getComponent(list, index) {
        try {
            return React.createElement(this.getComponentClass(list), {
                list,
                key: index,
                name: this.getName(list),
                description: this.getDescription(list),
                animalKind: this.getAnimalKind(list),
                medicineType: this.getMedicineType(list)
            }, null)
        } catch (e) {
            console.error(e);
            return <div key={index}/>;
        }
    }

    getComponentByTab(tab, index) {
        const {controlLists} = this.props;
        let list = controlLists.find(l => l.WordID === tab.key);
        return this.getComponent(list, index);
    }

    getDataForPrint(list) {
        const {farm} = this.props;
        switch (list.WordID) {
            case controlLists.INSEMINATIONS:
                return calculateInseminationsControlLists(list, farm);
            case controlLists.CONDITION:
                return calculateConditionControlLists(list, farm);
            case controlLists.USG:
                return calculateUSGControlLists(list, farm);
            case controlLists.SOWS_TO_INSEMINATION:
                return calculateSowsToInseminationControlLists(list, farm);
            case controlLists.SOWS_TO_TRANSFER:
                return calculateSowsToTransferControlLists(list, farm);
            case controlLists.PLANNED_PARTURITIONS:
                return calculatePlannedParturitionsControlLists(list, farm);
            case controlLists.SOWS_TO_GRAFTING:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.SOW, medicineTypes.VACCINE);
            case controlLists.BOARS_TO_GRAFRING:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.BOAR, medicineTypes.VACCINE);
            case controlLists.PORKER_TO_GRAFTING:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PORKER, medicineTypes.VACCINE);
            case controlLists.PIGLET_TO_GRAFTING:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PIGLET, medicineTypes.VACCINE);
            case controlLists.SOWS_FORAGE:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.SOW, medicineTypes.FORAGE);
            case controlLists.BOARS_FORAGE:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.BOAR, medicineTypes.FORAGE);
            case controlLists.PORKER_FORAGE:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PORKER, medicineTypes.FORAGE);
            case controlLists.PIGLET_FORAGE:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PIGLET, medicineTypes.FORAGE);
            case controlLists.SOWS_DOSATRON:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.SOW, medicineTypes.DOSATRON);
            case controlLists.BOARS_DOSATRON:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.BOAR, medicineTypes.DOSATRON);
            case controlLists.PORKER_DOSATRON:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PORKER, medicineTypes.DOSATRON);
            case controlLists.PIGLET_DOSATRON:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.PIGLET, medicineTypes.DOSATRON);
            case controlLists.SOWS_STIMULATOR:
                return calculateTreatmentControlLists(list, farm, AnimalTypes.SOW, medicineTypes.STIMULATOR);
            default:
                return [];
        }
    }

    getHeadersForPrint(list) {
        let headers;
        switch (list.WordID) {
            case controlLists.INSEMINATIONS:
                headers = getInseminationHeaders();
                break;
            case controlLists.CONDITION:
                headers = getConditionHeaders();
                break;
            case controlLists.USG:
                headers = getUSGHeaders();
                break;
            case controlLists.SOWS_TO_INSEMINATION:
                headers = getSowsToInseminationHeaders();
                break;
            case controlLists.SOWS_TO_TRANSFER:
                headers = getSowsToTransferHeaders();
                break;
            case controlLists.PLANNED_PARTURITIONS:
                headers = getPlannedParturitionHeaders();
                break;
            case controlLists.SOWS_TO_GRAFTING:
            case controlLists.BOARS_TO_GRAFRING:
            case controlLists.PORKER_TO_GRAFTING:
            case controlLists.PIGLET_TO_GRAFTING:
            case controlLists.SOWS_FORAGE:
            case controlLists.PORKER_FORAGE:
            case controlLists.BOARS_FORAGE:
            case controlLists.PIGLET_FORAGE:
            case controlLists.SOWS_DOSATRON:
            case controlLists.BOARS_DOSATRON:
            case controlLists.PORKER_DOSATRON:
            case controlLists.PIGLET_DOSATRON:
            case controlLists.SOWS_STIMULATOR:
                headers = getTreatmentHeaders();
                break;
            default:
                headers = [];
        }
        return [...headers, checkHeader];
    }

    onPrintClick = list => {
        this.setState({
            printing: true,
            name: this.getName(list),
            description: this.getDescription(list),
            data: this.getDataForPrint(list),
            headers: this.getHeadersForPrint(list),
            dates: list.getDatesForList()
        })
    };

    onPrintEnd = () => {
        this.setState({
            printing: false
        })
    }

    getHeader() {
        const {t, controlLists, match: {params: {active}}} = this.props;
        let header = t("controlLists.mainView.header");
        let list = controlLists.find(item => item.WordID === active);
        if (list) {
            header += ` - ${this.getName(list)}`;
        }
        return header;
    }

    render() {
        const {t, user: {LocalUserID}} = this.props;
        const {lists, active, tabs, printing, data, name, description, dates} = this.state;
        let rows = [];
        for (let [index, list] of lists.entries()) {
            if (this.getName(list)) {
                let animalKindCurrentList = this.getAnimalKind(list);
                let listBefore = lists[index - 1];
                if (listBefore) {
                    let animalKindListBefore = this.getAnimalKind(listBefore);
                    if (animalKindCurrentList !== animalKindListBefore) {
                        rows.push({fullWidthRow: true, animalKind: animalKindCurrentList});
                    }
                } else {
                    rows.push({fullWidthRow: true, animalKind: animalKindCurrentList});
                }
                rows.push(list);
            }
        }
        const headers = [
            {
                name: t("designation"),
                valueFormatter: this.getName,
                headerClassName: "grid-name",
                itemClassName: "grid-name"
            },
            {
                name: t("controlLists.mainView.description"),
                valueFormatter: this.getDescription
            },
            {
                name: "",
                component: <StarControlListComponent/>,
                headerClassName: "index",
                itemClassName: "index"
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: <CogControlListComponent/>
            },
            {
                name: "",
                headerClassName: "index",
                itemClassName: "index",
                component: <PrintControlListComponent onClick={this.onPrintClick}/>
            }
        ];
        return (
            <div className="control-list-view">
                <Header text={this.getHeader()} tabs={tabs} active={active}
                        onTabChange={this.onTabChange}/>
                <ViewContainer addPaddingForButtons>
                    <div className="position-relative w-100">
                        <TabContainer activeKey={active} onSlide={this.onTabChange}>
                            {
                                tabs.map((tab, index) =>
                                    tab.key === "all" ?
                                        <Tab key={index}>
                                            <Card>
                                                <TableGrid data={rows}
                                                           headers={headers} shouldIndex
                                                           mobileRow={<MobileControlListRow getName={this.getName}
                                                                                            getDescription={this.getDescription}
                                                                                            onRowClick={this.onRowClick}
                                                                                            onPrintClick={this.onPrintClick}/>}
                                                           onRowClick={this.onRowClick}
                                                           isFullWidthRow={row => row.fullWidthRow}
                                                           fullWidthRow={<ControlListFullWidthRow/>}
                                                           fullWidthRowGroups/>
                                            </Card>
                                        </Tab> :
                                        <Tab key={index}>
                                            {this.getComponentByTab(tab, index)}
                                        </Tab>
                                )
                            }
                        </TabContainer>
                        <div style={{marginTop: 15}}>
                            {
                                lists.filter(item => Array.isArray(item.WData.Favorite) ? !item.WData.Favorite.includes(LocalUserID) : true).map((list, index) =>
                                    <CSSTransition in={this.props.match.params.active === list.WordID} timeout={300}
                                                   classNames="slide-in" mountOnEnter unmountOnExit appear>
                                        {this.getComponent(list, index)}
                                    </CSSTransition>
                                )
                            }
                        </div>
                    </div>
                    {
                        printing &&
                        <div className="d-none">
                            <Printable printing={printing} name={name} additionalText={
                                <div>
                                    {description}
                                    <div>{t("controlLists.mainView.rangeOfDates")}: {dates.start.format("DD.MM.YYYY")} - {dates.end.format("DD.MM.YYYY")}</div>
                                </div>
                            } onPrintEnd={this.onPrintEnd}>
                                <PrintableTableGrid headers={this.state.headers} data={data} shouldIndex={true}/>
                            </Printable>
                        </div>
                    }
                </ViewContainer>
                <EditControlListSettingsModal/>
            </div>
        );
    }

}

ControlListView = connect(state => ({
    controlLists: state.dictionary.controlLists,
    farm: state.location.farm,
    user: state.user.user,
}))(ControlListView);

ControlListView = withTranslation()(ControlListView);

export default ControlListView;
