import React from "react";
import Tooltip from "../../../components/basics/tooltip/Tooltip";

export class PrintControlListComponent extends React.Component {

    onClick = e => {
        this.props.onClick(this.props.value);
        e.stopPropagation();
    };

    render() {
        return (
            <Tooltip tooltipContent={"Wydrukuj"} type={"info"}>
                <div onClick={this.onClick}>
                    <i className="fas fa-print pointer"/>
                </div>
            </Tooltip>
        );
    }

}

export default PrintControlListComponent;